export default class FilterComponent {
    trigger = document.querySelectorAll('[data-iris-advanced-search-filter]')
    resultsList = document.querySelectorAll('[data-iris-advanced-search-results-list]')
    hideAllTrigger = document.querySelectorAll('[data-iris-advanced-search-hide-all-strate]')

    constructor() {
        this.addEventListener()
    }

    addEventListener() {
        for (const section of document.querySelectorAll(
            '.iris-advanced-search-results__section:not(#all)',
        )) {
            section.style.display = 'none'
        }

        for (const trigger of this.trigger) {
            trigger.addEventListener('click', (e) => {
                e.preventDefault()
                for (const hideAllTrigger of this.hideAllTrigger) {
                    hideAllTrigger.classList.remove('active')
                }
                this.showResultsList()
                const href = e.currentTarget.getAttribute('href')
                for (const filterLink of document.querySelectorAll(
                    '[data-iris-advanced-search-filter]',
                )) {
                    filterLink.classList.remove('active')
                }
                e.currentTarget.classList.add('active')
                for (const section of document.querySelectorAll(
                    `.iris-advanced-search-results__section:not([href="${href}"])`,
                )) {
                    section.style.display = 'none'
                }
                document.getElementById(href.slice(1, href.length)).style.display = 'block'
            })
        }

        for (const hideAllTrigger of this.hideAllTrigger) {
            hideAllTrigger.addEventListener('click', () => {
                hideAllTrigger.classList.add('active')
                for (const trigger of this.trigger) {
                    trigger.classList.remove('active')
                }
                this.hideResultsList()
            })
        }
    }

    hideResultsList() {
        for (const resultsList of this.resultsList) {
            resultsList.style.display = 'none'
        }
    }

    showResultsList() {
        for (const resultsList of this.resultsList) {
            resultsList.style.display = 'grid'
        }
    }
}
