export default class ModalService {
    constructor() {
        const modals = document.querySelectorAll('#iris-advanced-search-modal')
        const toggle = document.querySelectorAll('[data-iris-advanced-search-toggle]')
        if (modals.length > 0 && toggle.length > 0) {
            import(
                '../components/modal.component' /* webpackChunkName: "scripts/iris-advanced-search-modal.component" */
            ).then(({ default: ModalComponent }) => {
                const modalComponent = new ModalComponent()
                modalComponent.addEventListener()
            })
        }
    }
}
