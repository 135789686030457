export function addObserverOnClass(element, callback, attributeName = 'class') {
    const observer = new MutationObserver((mutations) => {
        for (const mutation of mutations) {
            if (mutation.type === 'attributes' && mutation.attributeName === attributeName) {
                callback(mutation.target)
            }
        }
    })
    observer.observe(element, { attributes: true })
    return observer.disconnect
}
