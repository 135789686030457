export default class ModalComponent {
    body = document.querySelector('body')
    modal = document.getElementById('iris-advanced-search-modal')
    toggle = document.querySelectorAll('[data-iris-advanced-search-toggle]')
    eventOpen
    eventLoad

    constructor(modal, toggle) {
        this.createEvent()
        this.setModal(modal)
        this.setToggle(toggle)
        this.onLoadListener()
    }

    setModal(modal) {
        if (typeof modal !== 'undefined') {
            this.modal = document.getElementById(modal)
        }
    }

    setToggle(toggle) {
        if (typeof toggle !== 'undefined') {
            this.toggle = document.querySelectorAll(toggle)
        }
    }

    addEventListener() {
        this.modal.addEventListener('click', (e) => {
            if (e.target.classList.contains('iris-advanced-search-modal')) {
                this.close()
            }
        })
        for (const toggleElement of this.toggle) {
            toggleElement.addEventListener('click', () => {
                if (this.isOpen()) {
                    this.close()
                } else {
                    this.open()
                }
            })
        }
    }

    isOpen() {
        return window.getComputedStyle(this.modal).display === 'block'
    }

    close() {
        this.modal.style.display = 'none'
        this.body.classList.remove('iris-advanced-search-modal-is-open')
        let url = window.location.toString()
        url = url.split('#')[0]
        window.history.replaceState(null, document.title, url)
    }

    open() {
        this.modal.style.display = 'block'
        this.body.classList.add('iris-advanced-search-modal-is-open')
        document.dispatchEvent(this.eventLoad)
        document.dispatchEvent(this.eventOpen)
    }

    onLoadListener() {
        if (window.location.href.indexOf('#iris-advanced-search-modal') !== -1) {
            this.open()
        }
    }

    createEvent() {
        this.eventLoad = new Event('irisadvancedsearchload')
        this.eventOpen = new Event('irisadvancedsearchmodalisopening')
    }
}
