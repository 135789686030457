export default class AdvancedSearchClient {
    constructor(callback) {
        this.init().then(callback)
    }

    async init() {
        this.configuration = await this.getConfiguration()
    }

    async getConfiguration() {
        const response = await fetch(IRISAdvancedSearch.api.configuration)
        return await response.json()
    }
}
