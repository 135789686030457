import FilterComponent from './filter.component'

import { useEffect, useState } from '@wordpress/element'
import React from 'react'
import { useDebounce } from 'use-debounce'
import { addObserverOnClass } from '../utils/observer-class-event.util'
import InViewComponent from './in-view.component'
import ModalComponent from './modal.component'

export function SearchEngine() {
    const [loading, setLoading] = useState(false)
    const [inputValue, setInputValue] = React.useState('')
    const [debouncedValue] = useDebounce(inputValue, 300)
    const [strates, setStrates] = useState([])
    const [suggestions, setSuggestions] = useState([])
    const [numberResult, setNumberResult] = useState(0)

    const searchInput = React.useCallback((inputElement) => {
        if (inputElement) {
            inputElement.focus()
            document.addEventListener('irisadvancedsearchmodalisopening', () => {
                setTimeout(() => {
                    inputElement.focus()
                }, 200)
            })
        }
    }, [])

    function hasModalCloseButton() {
        return !!document.querySelector('[data-iris-advanced-search-with-close-button]')
    }

    function handleButtonsClick() {
        for (const item of document.querySelectorAll('[data-iris-advanced-search-add]')) {
            item.addEventListener('click', (e) => {
                const value = e.currentTarget.innerText
                setInputValue(value)
            })
        }

        const seeMore = document.querySelector('[data-iris-advanced-search-see-more]')
        if (seeMore) {
            seeMore.addEventListener('click', async (e) => {
                e.preventDefault()

                const seeMoreElement = e.currentTarget

                const page = seeMoreElement.dataset.irisAdvancedSearchSeeMore // no-unused-vars
                seeMoreElement.dataset.irisAdvancedSearchSeeMore =
                    Number.parseInt(seeMoreElement.dataset.irisAdvancedSearchSeeMore) + 1

                document
                    .getElementById('all')
                    .querySelector('[data-iris-advanced-search-results-section-list]').innerHTML +=
                    '<div class="iris-card skeleton"></div><div class="iris-card skeleton"></div><div class="iris-card skeleton"></div><div class="iris-card skeleton"></div>'

                const result = await (
                    await fetch(IRISAdvancedSearch.api.seeMore, {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        credentials: 'include',
                        body: JSON.stringify({
                            query: inputValue,
                            page,
                        }),
                    })
                ).json()

                for (const card of document
                    .getElementById('all')
                    .querySelectorAll(
                        '.iris-advanced-search-results__section__list .iris-card.skeleton',
                    )) {
                    card.remove()
                }

                let allStrateHasMore = false

                for (const strate of result.strates ?? []) {
                    if (strate.slug === 'all') {
                        allStrateHasMore = strate.hasMore
                    }
                    if (strate.nbrPosts > 0) {
                        const strateSection = document.getElementById(strate.slug)
                        strateSection.querySelector(
                            '[data-iris-advanced-search-results-section-list]',
                        ).innerHTML += strate.posts

                        document.querySelector(
                            `[data-iris-advanced-search-filter][href="#${strate.slug}"]`,
                        ).parentElement.style.display = 'block'
                    }
                }

                new InViewComponent()

                for (const card of document.querySelectorAll('.iris-card.reveal')) {
                    addObserverOnClass(card, (el) => {
                        triggerSeeMore(el)
                    })
                }

                if (!allStrateHasMore) {
                    seeMoreElement.remove()
                }
            })
        }
    }

    function isSearching() {
        return (
            inputValue.length >=
            window.advancedSearchClient.configuration.characterCountBeforeSearch
        )
    }

    function hasTagsEnabled() {
        return IRISAdvancedSearch.options.isSuggestionDisabled !== '1'
    }

    function sendEventIsSearching() {
        let isSearchingEvent = new Event('irisadvancedsearchnosearching')
        if (true === (isSearching() && !loading)) {
            isSearchingEvent = new Event('irisadvancedsearchissearching')
        }
        document.dispatchEvent(isSearchingEvent)
    }

    async function getStrates(query) {
        if (!isSearching()) {
            setNumberResult(0)
            setStrates([])
            setSuggestions([])
            return
        }

        setLoading(true)
        const result = await (
            await fetch(IRISAdvancedSearch.api.search, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                credentials: 'include',
                body: JSON.stringify({
                    query,
                }),
            })
        ).json()

        setStrates(result.strates)
        setSuggestions(result.suggestions)
        setNumberResult(result.nbrPosts)
        setLoading(false)
        new InViewComponent()

        for (const card of document.querySelectorAll('.iris-card.reveal')) {
            addObserverOnClass(card, (e) => {
                triggerSeeMore(e)
            })
        }
    }

    function triggerSeeMore(e) {
        const currentSectionId = e
            .closest('[data-iris-advanced-search-results-section]')
            .getAttribute('id')
        const currentSection = document.getElementById(currentSectionId)

        if (currentSection.querySelectorAll('[data-iris-advanced-search-see-more]').length > 0) {
            const restRevealByRow = currentSection.querySelectorAll('.reveal').length

            if (restRevealByRow === 4) {
                currentSection.querySelector('[data-iris-advanced-search-see-more]').click()
            }
        }
    }

    useEffect(() => {
        handleButtonsClick()
        sendEventIsSearching()
        new FilterComponent()
    }, [inputValue, loading])

    useEffect(() => {
        if (!loading) {
            getStrates(inputValue)
        }
    }, [debouncedValue])

    const handleInputChange = (event) => {
        const value = event.target.value
        setInputValue(value)
    }

    function closeModal() {
        const modal = new ModalComponent()
        modal.close()
    }

    return (
        <>
            <div className="iris-advanced-search-input">
                {inputValue.length > 0 && (
                    <i
                        className="iris-advanced-search-input__icon ficore ficore--replay"
                        onClick={() => setInputValue('')}
                        onKeyDown={() => setInputValue('')}
                        role="button"
                        tabIndex="0"
                    />
                )}
                {inputValue.length === 0 && (
                    <i className="iris-advanced-search-input__icon ficore ficore--search" />
                )}
                <input
                    className={`iris-advanced-search-input__field ${
                        numberResult !== 0 ? 'iris-advanced-search-input__field--with-result' : ''
                    }`}
                    placeholder={IRISAdvancedSearch.trad.search}
                    type="text"
                    value={inputValue}
                    data-iris-advanced-search-input=""
                    onChange={handleInputChange}
                    ref={searchInput}
                />
                <div className="iris-advanced-search-input__tools">
                    {numberResult !== 0 && (
                        <div className="iris-advanced-search-input__result-number">
                            {numberResult} {IRISAdvancedSearch.trad.results}
                        </div>
                    )}
                    {hasModalCloseButton() === true && (
                        <button
                            className="iris-advanced-search-input__clear ficore ficore--close"
                            key="iris-advanced-seach-close-modal"
                            type="button"
                            onClick={closeModal}
                        >
                            <span className="sr-only">{IRISAdvancedSearch.trad.closeModal}</span>
                        </button>
                    )}
                </div>
            </div>

            {!isSearching() && (
                <>
                    <div
                        // biome-ignore lint/security/noDangerouslySetInnerHtml: <explanation>
                        dangerouslySetInnerHTML={{
                            __html: window.advancedSearchClient.configuration.searchHistory,
                        }}
                    />
                    <div
                        // biome-ignore lint/security/noDangerouslySetInnerHtml: <explanation>
                        dangerouslySetInnerHTML={{
                            __html: window.advancedSearchClient.configuration.topSearches,
                        }}
                    />
                </>
            )}

            {isSearching() && loading && (
                <div className="iris-advanced-search-in-loading">
                    {hasTagsEnabled() && (
                        <div className="iris-advanced-search-tags">
                            <div className="iris-advanced-search-tags__list">
                                <div />
                                <div />
                            </div>
                        </div>
                    )}
                    <div className="iris-advanced-search-results">
                        <div className="iris-advanced-search-results__filter anchors">
                            <div className="anchors__list">
                                <div className="anchors__item">
                                    <span className="anchors__item__link" />
                                </div>
                                <div className="anchors__item">
                                    <span className="anchors__item__link" />
                                </div>
                                <div className="anchors__item">
                                    <span className="anchors__item__link" />
                                </div>
                            </div>
                        </div>
                        <div className="iris-advanced-search-results__list">
                            <div className="iris-advanced-search-results__section">
                                <div className="iris-advanced-search-results__section__header">
                                    <span className="iris-advanced-search-results__section__header__title" />
                                </div>
                                <div className="iris-advanced-search-results__section__list">
                                    <div className="iris-card" />
                                    <div className="iris-card" />
                                    <div className="iris-card" />
                                    <div className="iris-card" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {isSearching() && numberResult === 0 && !loading && (
                <p className={'iris-advanced-search__no-result'}>
                    {IRISAdvancedSearch.trad.noResult}
                </p>
            )}

            {isSearching() && numberResult > 0 && !loading && (
                <>
                    {/* biome-ignore lint/security/noDangerouslySetInnerHtml: <explanation> */}
                    <div dangerouslySetInnerHTML={{ __html: suggestions }} />
                    {/* biome-ignore lint/security/noDangerouslySetInnerHtml: <explanation> */}
                    <div dangerouslySetInnerHTML={{ __html: strates }} />
                </>
            )}

            <div
                // biome-ignore lint/security/noDangerouslySetInnerHtml: <explanation>
                dangerouslySetInnerHTML={{
                    __html: window.advancedSearchClient.configuration.coldOffers,
                }}
            />
        </>
    )
}
