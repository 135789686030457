export default class InViewComponent {
    constructor() {
        if (window.matchMedia('(max-width: 650px)').matches) {
            InViewComponent.scroll_reveal()
        }
    }

    static scroll_reveal() {
        const ratio = 0.1
        const options = {
            root: null,
            rootMargin: '0px',
            threshold: 0.1,
        }

        const handleIntersect = (entries, observer) => {
            for (const entry of entries) {
                if (entry.intersectionRatio >= ratio) {
                    entry.target.classList.remove('reveal')
                    observer.unobserve(entry.target)
                }
            }
        }

        document.documentElement.classList.add('reveal-loaded')
        const observer = new IntersectionObserver(handleIntersect, options)
        for (const element of document.querySelectorAll('.reveal')) {
            observer.observe(element)
        }
    }
}
